import hideLoaderOperation from 'editor/src/store/app/operation/hideLoaderOperation';
import { LoaderType } from 'editor/src/store/app/types';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import removeImagesPerImageIdOperation from 'editor/src/store/design/operation/removeImagesPerImageIdOperation';
import replaceImageAfterUploadOperation from 'editor/src/store/design/operation/replaceImageAfterUploadOperation';
import { updateMediaImageUrlAction } from 'editor/src/store/design/slice';
import { Dimensions } from 'editor/src/store/design/types';
import { EMPTY_PREVIEW_DIM } from 'editor/src/store/gallery/config';
import getSessionImages from 'editor/src/store/gallery/selector/getSessionImages';
import { replaceImageAction, updateImageAction, replaceSessionImageAction } from 'editor/src/store/gallery/slice';
import { ImageState, UploadedImageData } from 'editor/src/store/gallery/types';
import getMimeTypeEnum from 'editor/src/store/gallery/utils/getMimeTypeEnum';
import { logUpload } from 'editor/src/store/gallery/utils/uploadLogging';
import type { AsyncThunk } from 'editor/src/store/hooks';

import isWebPSupported from 'editor/src/util/isWebPSupported';
import loadImageDimensions from 'editor/src/util/loadImageDimensions';
import toastController from 'editor/src/util/toastController';

import setImageIfCurrentImageIsEmptyOperation from '../../editor/operation/setImageIfCurrentImageIsEmptyOperation';
import isUseNotUploadedImagesAllowed from '../../editor/selector/isUseNotUploadedImagesAllowed';

import resetSessionImageOperation from './resetSessionImageOperation';

const updateImageOnUploadOperation =
  (imageData: UploadedImageData & { assetId?: string }): AsyncThunk =>
  async (dispatch, getState, { i18n }) => {
    const thumbnailUrl = isWebPSupported ? imageData.thumbnailUrl || imageData.url : imageData.url;
    const uniqColors = new Set([...(imageData.file.colors?.fill ?? []), ...(imageData.file.colors?.stroke ?? [])]);
    const imageUpdate = {
      id: imageData.file.id,
      state: ImageState.UPLOADED,
      name: imageData.file.name,
      type: getMimeTypeEnum(imageData.file.type || imageData.mimeType),
      url: imageData.url,
      thumbnailUrl,
      quantizedUrl: imageData.quantizedUrl,
      label: imageData.file.label,
      colors: uniqColors.size > 0 ? [...uniqColors] : undefined,
      quantizedColors: imageData.file.quantizedColors,
      exif: imageData.file.exif,
    };

    const processingTime = Date.now();

    let dimensions: Dimensions;
    const existingImage = getState().gallery.images.find((image) => image.uploaderId === imageData.file.id);

    if (imageData.file.width && imageData.file.height) {
      dimensions = {
        width: imageData.file.width,
        height: imageData.file.height,
      };
    } else {
      try {
        dimensions = await loadImageDimensions(imageUpdate.thumbnailUrl, 'anonymous', {
          executor: 'updateImageOnUpload',
          image: imageData,
          existingImage,
        });
      } catch {
        toastController.warningPersist(
          i18n.t('editor-image-loading-failed'),
          i18n.t('editor-image-loading-failed-cant-add'),
        );
        dimensions = EMPTY_PREVIEW_DIM;
        imageUpdate.state = ImageState.FAILED;
      }
    }

    logUpload(
      imageData.file.id,
      processingTime,
      imageUpdate.state === ImageState.UPLOADED ? 'success' : 'processing-failed',
    );

    const hasAssetDimensions = !!imageData.file.width && !!imageData.file.height;

    batch(() => {
      if (imageUpdate.state === ImageState.FAILED) {
        dispatch(removeImagesPerImageIdOperation(imageData.file.id));
      }

      dispatch(hideLoaderOperation(LoaderType.EditorArea));
      const { width, height } = dimensions;
      const newImageHasAssetDimensions = !!existingImage?.hasAssetDimensions || hasAssetDimensions;

      if (!imageData.assetId) {
        dispatch(
          updateImageAction({
            ...imageUpdate,
            width,
            height,
            hasAssetDimensions: newImageHasAssetDimensions,
          }),
        );
        dispatch(
          updateMediaImageUrlAction({
            imageId: imageData.file.id,
            imageUrl: imageData.url,
          }),
        );
      } else {
        dispatch(
          replaceImageAfterUploadOperation(
            imageData.file.id,
            imageData.assetId,
            imageData.url,
            dimensions,
            existingImage,
          ),
        );
        dispatch(
          replaceImageAction({
            prevImageId: imageData.file.id,
            image: {
              ...existingImage,
              ...imageUpdate,
              width,
              height,
              id: imageData.assetId,
              name: existingImage?.name || imageUpdate.name || '',
              source:
                imageData.file.source && imageData.file.source !== 'url'
                  ? imageData.file.source
                  : existingImage?.source,
              hasAssetDimensions: newImageHasAssetDimensions,
            },
          }),
        );
        dispatch(
          replaceSessionImageAction({
            prevImageId: imageData.file.id,
            currentImageId: imageData.assetId,
          }),
        );
      }

      const state = getState();
      const isStillUploading = getSessionImages(state).some(
        (image) => image.state === ImageState.LOCAL_PREVIEW || image.state === ImageState.REMOTE_PREVIEW,
      );
      if (!isStillUploading) {
        dispatch(resetSessionImageOperation());
      }

      const canUseImage = isUseNotUploadedImagesAllowed(state);
      if (imageData.assetId && !canUseImage) {
        dispatch(setImageIfCurrentImageIsEmptyOperation(imageData.assetId));
      }
    });
  };

export default updateImageOnUploadOperation;
