import { designs } from '@gelatoas/design-editor-calendar';
import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_LOCALE } from 'editor/src/util/locales';

import setGridDesignsLocaleReducer from './reducer/setGridDesignsLocaleReducer';
import setGridDesignsReducer from './reducer/setGridDesignsReducer';
import setGridEventsReducer from './reducer/setGridEventsReducer';
import { GridConfigState } from './types';

export const gridDesignsInitialState: GridConfigState = {
  grids: designs.gridDesigns,
  gridEvents: [],
  locale: DEFAULT_LOCALE,
};

export const slice = createSlice({
  name: 'gridDesigns',
  initialState: gridDesignsInitialState,
  reducers: {
    setGridDesignsAction: setGridDesignsReducer,
    setGridEventsAction: setGridEventsReducer,
    setGridDesignsLocaleAction: setGridDesignsLocaleReducer,
  },
});

export const { setGridDesignsAction, setGridEventsAction, setGridDesignsLocaleAction } = slice.actions;

export default slice.reducer;
