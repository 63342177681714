// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container--xEonU{position:relative;display:flex;justify-content:center;align-items:center;overflow:hidden;-webkit-overflow-scrolling:touch}.image--zPXFL{width:auto;height:auto;max-width:100%;max-height:100%;object-fit:contain;transition:transform .3s ease-out;transform-origin:center center;user-select:none;-webkit-user-drag:none;touch-action:pan-x pan-y}.image--zPXFL.zoomed--kBbQH{cursor:zoom-out}.image--zPXFL:not(.zoomed--kBbQH){cursor:zoom-in}`, "",{"version":3,"sources":["webpack://./../editor/src/component/ZoomableImage/index.module.scss"],"names":[],"mappings":"AAAA,kBACI,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,gCAAA,CAGF,cACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,iCAAA,CACA,8BAAA,CACA,gBAAA,CACA,sBAAA,CACA,wBAAA,CAEA,4BACE,eAAA,CAGF,kCACE,cAAA","sourcesContent":[".container {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;\n    -webkit-overflow-scrolling: touch;\n  }\n  \n  .image {\n    width: auto;\n    height: auto;\n    max-width: 100%;\n    max-height: 100%; \n    object-fit: contain;\n    transition: transform 0.3s ease-out;\n    transform-origin: center center;\n    user-select: none;\n    -webkit-user-drag: none;\n    touch-action: pan-x pan-y;\n  \n    &.zoomed {\n      cursor: zoom-out;\n    }\n  \n    &:not(.zoomed) {\n      cursor: zoom-in;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container--xEonU`,
	"image": `image--zPXFL`,
	"zoomed": `zoomed--kBbQH`
};
export default ___CSS_LOADER_EXPORT___;
