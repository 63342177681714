import cn from 'classnames';
import React from 'react';

import IconLoading from '../Icon/IconLoading';

import styles from './index.module.scss';

interface Props {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  danger?: boolean;

  onClick?: (e: MouseEvent) => void;
  className?: string;
  on?: boolean;
  stopPropagation?: boolean;
  children: React.ReactNode;
  noPadding?: boolean;
  showLoader?: boolean;
  fullWidth?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

function Button({
  children,
  primary,
  disabled,
  rounded = false,
  danger,
  onClick,
  secondary,
  className,
  on,
  stopPropagation,
  noPadding,
  tertiary,
  transparent,
  showLoader,
  fullWidth,
  type = 'button',
}: Props) {
  const handleClick: any = (e: MouseEvent) => {
    if (disabled) {
      return;
    }
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      className={cn(
        styles.button,
        {
          [styles.primary]: primary,
          [styles.secondary]: secondary,
          [styles.tertiary]: tertiary,
          [styles.transparent]: transparent,
          [styles.on]: on,
          [styles.disabled]: disabled,
          [styles.noPadding]: noPadding,
          [styles.rounded]: rounded,
          [styles.danger]: danger,
          [styles.showLoader]: showLoader,
          [styles.fullWidth]: fullWidth,
        },
        className,
      )}
      disabled={disabled}
      onClick={handleClick}
      type={type}
    >
      {showLoader ? <IconLoading /> : children}
    </button>
  );
}

export default Button;
