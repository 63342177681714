import { createSlice } from '@reduxjs/toolkit';

import getCustomerDeviceType from 'editor/src/util/getCustomerDeviceType';

import popupInitialState from './module/popup/initialState';
import openPopupReducer from './module/popup/reducer/openPopupReducer';
import addLoaderReducer from './reducer/addLoaderReducer';
import removeLoaderReducer from './reducer/removeLoaderReducer';
import setActiveAddLayerDropdownReducer from './reducer/setActiveAddLayerDropdownReducer';
import setActiveTopMenuDropdownReducer from './reducer/setActiveTopMenuDropdownReducer';
import setCustomerDeviceTypeReducer from './reducer/setCustomerDeviceTypeReducer';
import setMobileViewReducer from './reducer/setMobileViewReducer';
import setSimpleDropdownReducer from './reducer/setSimpleDropdownReducer';
import showActionBarReducer from './reducer/showActionBarReducer';
import { AppState } from './types';

export const appInitialState: AppState = {
  dropdowns: {
    activeAddLayerDropdown: undefined,
    activeTopMenuDropdown: undefined,
    mobileMoreMenuDropdown: undefined,
  },
  simpleDropdown: {
    name: undefined,
    data: undefined,
  },
  customerDeviceType: getCustomerDeviceType(),
  activeActionBar: undefined,
  popup: popupInitialState,
  activeLoaders: {},
  mobileView: 'spreads',
};

export const slice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    setActiveTopMenuDropdownAction: setActiveTopMenuDropdownReducer,
    setActiveAddLayerDropdownAction: setActiveAddLayerDropdownReducer,
    setCustomerDeviceTypeAction: setCustomerDeviceTypeReducer,
    showActionBarAction: showActionBarReducer,
    setSimpleDropdownAction: setSimpleDropdownReducer,
    openPopupAction: openPopupReducer,
    addLoaderAction: addLoaderReducer,
    removeLoaderAction: removeLoaderReducer,
    setMobileViewAction: setMobileViewReducer,
  },
});

export const {
  setActiveTopMenuDropdownAction,
  setActiveAddLayerDropdownAction,
  setCustomerDeviceTypeAction,
  showActionBarAction,
  setSimpleDropdownAction,
  openPopupAction,
  addLoaderAction,
  removeLoaderAction,
  setMobileViewAction,
} = slice.actions;

export default slice.reducer;
