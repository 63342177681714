import { PopupState } from './module/popup/types';

export interface AppState {
  dropdowns: DropdownsState;
  simpleDropdown: SimpleDropdownState;
  customerDeviceType: DeviceType;
  activeActionBar?: ActionBarNames;
  popup: PopupState;
  activeLoaders: { [key in LoaderType]?: boolean };
  mobileView: MobileView;
}

export type MobileView = 'spreads' | 'canvas';

export enum ActionBarNames {
  FontFamilies = 'FontFamilies',
  FontColors = 'FontColors',
  ReplaceImage = 'ReplaceImage',
}

export enum TopMenuDropdown {
  Position = 'Position',
  ProductFitting = 'ProductFitting',
  ImageEffects = 'ImageEffects',
  FontSize = 'FontSize',
  TextAlign = 'TextAlign',
  TextSpacing = 'TextSpacing',
  TextFitting = 'TextFitting',
  CropZoom = 'CropZoom',
  Personalization = 'Personalization',
  SVGColor = 'SVGColor',
  LineStyles = 'LineStyles',
  RectangleStyles = 'RectangleStyles',
  LineEdge1 = 'LineEdge1',
  LineEdge2 = 'LineEdge2',
  ThreadColor = 'ThreadColor',
  ProductSwitcher = 'ProductSwitcher',
}

export enum DeviceType {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
}

export enum LoaderType {
  EditorArea = 'EditorArea',
  ProductPersonalizerPreview = 'ProductPersonalizerPreview',
}

export interface DropdownsState {
  activeAddLayerDropdown?: string;
  activeTopMenuDropdown?: TopMenuDropdown;
  mobileMoreMenuDropdown?: string;
}

export interface SimpleDropdownState {
  name?: string | null;
  data?: any;
}

export interface AuthHeader {
  [key: string]: string;
}

export declare type TextEffectControl = 'shadow' | 'outline' | 'pattern' | 'curve';
