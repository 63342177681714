import { GridDate } from '@gelatoas/design-editor-calendar';

import type { i18n } from 'i18next';

const THRESHOLD_FOR_NEXT_YEAR = 6; // if the current month is July calendar should start from next year
const DAYS_IN_WEEK = 7;

export function getStartingYear(currentDate = new Date()): number {
  const currentYear = currentDate.getFullYear();
  return currentDate.getMonth() >= THRESHOLD_FOR_NEXT_YEAR ? currentYear + 1 : currentYear;
}

export type WeekDay = { label: string; value: number };
export function getWeekDays(i18n: i18n): WeekDay[] {
  const intlWeekday = new Intl.DateTimeFormat(i18n.languageWithDash, { weekday: 'long' });
  const startDate = new Date(2022, 9, 14, 0, 0, 0);
  const weekDays: WeekDay[] = [];
  for (let i = 0; i < DAYS_IN_WEEK; i += 1) {
    const date = startDate.setDate(startDate.getDate() + 1);
    weekDays.push({
      value: startDate.getDay(),
      label: intlWeekday.format(date),
    });
  }
  return weekDays;
}

export function getClosest1stOfTheMonth(time: number): GridDate {
  const date = new Date(time);
  const dayDate = date.getDate();
  const year = date.getFullYear();
  const month = date.getMonth();
  if (dayDate > 15) {
    const newDate = new Date(year, date.getMonth() + 1, 1);
    return { year: newDate.getFullYear(), month: newDate.getMonth() };
  }
  return { year, month };
}

export function getTranslatedMonth(month: string, locale: string): string {
  try {
    const selectedDate = new Date(`1 ${month}`);
    if (Number.isNaN(selectedDate.getTime())) {
      return month;
    }
    return selectedDate.toLocaleString(locale, {
      month: 'long',
      caseFirst: 'upper',
    } as any);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.info(`Can not parse month ${month}`, error);
    return month;
  }
}
