import React, { useRef, useEffect } from 'react';

import styles from './index.module.scss';

interface ZoomableImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  className?: string;
  containerClassName?: string;
  onLoad?: () => void;
}

const DOUBLE_TAP_THRESHOLD = 300; // ms
const MIN_ZOOM_SCALE = 1;
const MAX_ZOOM_SCALE = 3;
const DOUBLE_TAP_ZOOM_SCALE = 2;
const SMOOTH_ZOOM_DAMPENING = 0.1;

function ZoomableImage({ src, alt, className, containerClassName, onLoad, style }: ZoomableImageProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const lastTapTime = useRef(0);
  const initialDistance = useRef<number>(0);
  const currentScale = useRef<number>(1);
  const isZoomed = useRef<boolean>(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return undefined;
    }

    const handleDoubleTap = (e: TouchEvent): void => {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTapTime.current;

      if (tapLength < DOUBLE_TAP_THRESHOLD && tapLength > 0) {
        e.preventDefault();
        const img = containerRef.current?.querySelector('img');
        if (img) {
          isZoomed.current = !isZoomed.current;
          currentScale.current = isZoomed.current ? DOUBLE_TAP_ZOOM_SCALE : MIN_ZOOM_SCALE;

          const rect = img.getBoundingClientRect();
          const offsetX = e.touches[0].clientX - rect.left;
          const offsetY = e.touches[0].clientY - rect.top;
          const percentX = offsetX / rect.width;
          const percentY = offsetY / rect.height;

          img.style.transformOrigin = `${percentX * 100}% ${percentY * 100}%`;
          img.style.transform = `scale(${currentScale.current})`;
          img.classList.toggle(styles.zoomed);

          if (isZoomed.current) {
            const scrollLeft = (img.offsetWidth * currentScale.current - container.clientWidth) * percentX;
            const scrollTop = (img.offsetHeight * currentScale.current - container.clientHeight) * percentY;

            container.scrollTo({
              top: scrollTop,
              left: scrollLeft,
              behavior: 'smooth',
            });
          } else {
            container.scrollTo({ top: 0, left: 0 });
          }
        }
      }
      lastTapTime.current = currentTime;
    };

    const handleTouchStart = (e: TouchEvent): void => {
      if (e.touches.length === 2) {
        e.preventDefault();
        initialDistance.current = Math.hypot(
          e.touches[0].pageX - e.touches[1].pageX,
          e.touches[0].pageY - e.touches[1].pageY,
        );
      }
    };

    const handleTouchMove = (e: TouchEvent): void => {
      if (e.touches.length === 2) {
        e.preventDefault();
        const distance = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);

        const scaleDiff = (distance / initialDistance.current - 1) * SMOOTH_ZOOM_DAMPENING;
        const newScale = Math.min(Math.max(currentScale.current + scaleDiff, MIN_ZOOM_SCALE), MAX_ZOOM_SCALE);

        const img = containerRef.current?.querySelector('img');
        if (img) {
          img.style.transition = 'transform 0.2s ease-out';
          img.style.transform = `scale(${newScale})`;
          currentScale.current = newScale;
          isZoomed.current = newScale > MIN_ZOOM_SCALE;
          img.classList.toggle(styles.zoomed, isZoomed.current);
        }
      }
    };

    container.addEventListener('touchstart', handleTouchStart);
    container.addEventListener('touchstart', handleDoubleTap);
    container.addEventListener('touchmove', handleTouchMove);

    return () => {
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchstart', handleDoubleTap);
      container.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  const handleImageLoad = () => {
    onLoad?.();
  };

  return (
    <div ref={containerRef} className={`${styles.container} ${styles.touchPinchZoom} ${containerClassName || ''}`}>
      <img
        src={src}
        alt={alt}
        className={`${styles.image} ${className || ''}`}
        style={style}
        onLoad={handleImageLoad}
      />
    </div>
  );
}

export default ZoomableImage;
