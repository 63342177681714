import React, { useState } from 'react';

import { useSelector } from 'editor/src/store/hooks';

import { RootState } from 'editor/src/store';
import useSpreadTitle from 'editor/src/util/design/useSpreadTitle';
import useBlobAsUrl from 'editor/src/util/useBlobAsUrl';

import IconLoading from 'editor/src/component/Icon/IconLoading';
import ZoomableImage from 'editor/src/component/ZoomableImage';

import styles from './index.module.scss';

interface Props {
  url?: string;
  blob: Blob | null;
  index: number;
  name?: string;
  spreadIndex?: number;
  showNameSetting?: boolean;
}

function ListItem({ url, blob, name, spreadIndex, index, showNameSetting = false }: Props) {
  const blobUrl = useBlobAsUrl(blob);
  const imageUrl = url || blobUrl;
  const [loaded, setLoaded] = useState(false);
  const designData = useSelector((state: RootState) => state.design.designData);

  const spreadTitle = useSpreadTitle(
    spreadIndex !== undefined ? designData?.spreads[spreadIndex]?.name || '' : '',
    spreadIndex ?? index,
    undefined,
  );
  const showName = showNameSetting && spreadTitle;
  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <div className={styles.listItem}>
      <ZoomableImage
        src={imageUrl}
        alt={name || ''}
        draggable={false}
        onLoad={handleImageLoad}
        style={{ display: loaded ? 'block' : 'none' }}
      />
      {!loaded && <IconLoading className={styles.loader} />}
      {showName && loaded && <div className={styles.label}>{spreadTitle}</div>}
    </div>
  );
}

export default React.memo(ListItem);
