import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

import type { DesignData, MediaImage, MediaText } from 'editor/src/store/design/types';

import getLayoutFrames from 'editor/src/util/reflectDesignData/getLayoutFrames';
import { ReflectContext } from 'editor/src/util/reflectDesignData/getReflectContext';
import reflectMediaElement from 'editor/src/util/reflectDesignData/reflectMediaElement';
import { getMediaBoxes } from 'editor/src/util/reflectDesignData/reflectMediaElements';
import updateImageElement from 'editor/src/util/reflectDesignData/updateImageElement';

import getSpreadWidthFromSpread from '../selector/getSpreadWidthFromSpread';

import updateTextPropertiesWithoutRender from './updateTextPropertiesWithoutRender';

const calendarFrontPostfix = ' front';

function applyDesign(
  previousDesign: DesignData,
  previousOriginalDesign: DesignData | undefined,
  nextDesign: DesignData,
  allowNew: boolean,
  reflectContext: ReflectContext | undefined,
): DesignData {
  const newDesign = cloneDeep(nextDesign);
  let resetLayouts = false;
  previousDesign.spreads.forEach((previousSpread, sourceSpreadIndex) => {
    const nextSpread = newDesign.spreads.find((spread) => {
      const matchSpread = previousSpread.name === spread.name;
      if (matchSpread) {
        return true;
      }
      if (previousDesign.calendar) {
        const matchCalendarSpread =
          previousSpread.name.toLowerCase().replace(calendarFrontPostfix, '') ===
          spread.name.toLowerCase().replace(calendarFrontPostfix, '');
        if (matchCalendarSpread) {
          resetLayouts = true;
          return true;
        }
      }
      return false;
    });
    const previousOriginalSpread = previousOriginalDesign?.spreads[sourceSpreadIndex];
    const sourcePageIndex = previousSpread.pages.findIndex(
      // it's possible to have 'blank' + media on photobook front-inner spread
      (page) => (!!page.groups.mediabox?.[0] || !!page.groups.blank?.[0]) && !!page.groups.media,
    );
    const previousOriginalPage = previousOriginalSpread?.pages[sourcePageIndex];
    const sourcePage = previousSpread.pages[sourcePageIndex];
    if (!sourcePage) {
      return;
    }

    const destPage = nextSpread?.pages[sourcePageIndex];
    if (!nextSpread || !destPage) {
      return;
    }
    let nextMedia = destPage.groups.media ?? [];

    // reset spread if the layout is different from default
    const layoutChanged = [nextSpread.layoutSchemaName, previousOriginalSpread?.layoutSchemaName].includes(
      previousSpread?.layoutSchemaName,
    );
    if (!layoutChanged && !resetLayouts) {
      nextSpread.layoutSchemaName = previousSpread.layoutSchemaName;
      nextMedia = [];
    }

    // If the old design has the same conditions as the new design, copy only the activeOptionId from the old design to preserve the selected option when the variant is changed.
    if (
      previousSpread.conditionGroup?.conditions &&
      Object.keys(previousSpread.conditionGroup.conditions).length > 0 &&
      nextSpread?.conditionGroup &&
      Object.keys(nextSpread.conditionGroup.conditions).length > 0
    ) {
      const oldConditions = previousSpread.conditionGroup.conditions;
      const newConditions = nextSpread.conditionGroup.conditions;

      Object.keys(newConditions).forEach((key) => {
        if (oldConditions[key]?.id === newConditions[key]?.id) {
          newConditions[key].activeOptionId = oldConditions[key].activeOptionId;
        }
      });
    }

    destPage.groups.media = nextMedia;
    const elementNames = new Set<string>(nextMedia.map((element) => element.name));

    // used to reflect media elements
    const mediaBoxes = getMediaBoxes(previousSpread, nextSpread);
    const spreadGroup = previousDesign.spread_groups?.find((spreadGroup) =>
      spreadGroup.spreadIndexes.includes(sourceSpreadIndex),
    );
    const sourceLayoutFrames = getLayoutFrames(
      previousSpread,
      reflectContext?.layouts ?? [],
      previousDesign.spreads,
      spreadGroup,
    );
    const destLayoutFrames = getLayoutFrames(nextSpread, reflectContext?.layouts ?? [], newDesign.spreads, spreadGroup);

    const destSpreadWidth = getSpreadWidthFromSpread(nextSpread);
    const mediaContext =
      reflectContext?.fonts && reflectContext?.gridDesigns
        ? {
            fonts: reflectContext.fonts,
            gridDesigns: reflectContext.gridDesigns,
          }
        : undefined;

    previousSpread.pages[sourcePageIndex].groups.media?.forEach((element, index) => {
      if (allowNew && element.createdWhileEmbedded && mediaBoxes && !resetLayouts) {
        const newElement = reflectMediaElement(
          mediaBoxes.sourceMediaBox,
          mediaBoxes.destMediaBox,
          sourceLayoutFrames,
          destLayoutFrames,
          previousSpread,
          nextSpread,
          destSpreadWidth,
          element,
          'adapt',
          mediaContext,
          newDesign.calendar,
        );
        if (elementNames.has(newElement.name)) {
          newElement.name += ` ${uuidv4()}`;
          elementNames.add(newElement.name);
        }
        nextMedia.push(newElement);
        return;
      }

      if (element.personalizationLocked) {
        return;
      }

      let nextElement = nextMedia.find((el) => {
        if (previousDesign.calendar && (element as MediaImage).imageId) {
          const marchImage = resetLayouts || (el as MediaImage).layoutFrameId === (element as MediaImage).layoutFrameId;
          return !el.personalizationLocked && marchImage && el.type === element.type;
        }
        return !el.personalizationLocked && el.name === element.name && el.type === element.type;
      });

      const originalElement =
        !nextElement &&
        previousOriginalPage?.groups.media?.find((originalElement) => originalElement.name === element.name);

      // look for elements with the same initial values
      if (originalElement) {
        nextElement = nextMedia.find(
          (el) =>
            !el.personalizationLocked &&
            ((el.type === 'text' && el.type === originalElement.type && el.extra.text === originalElement.extra.text) ||
              (el.type === 'image' && el.type === originalElement.type && el.imageId === originalElement.imageId)),
        );
      }

      if (nextElement) {
        if (element.type === 'image') {
          const imageUpdated = (previousOriginalPage?.groups.media?.[index] as MediaImage)?.imageId !== element.imageId;
          const nextImage = nextElement as MediaImage;
          if (imageUpdated) {
            nextImage.imageId = element.imageId;
            nextImage.sample = element.sample;
          }
          updateImageElement(element, nextImage, 'adapt', true);
        } else if (element.type === 'text') {
          const nextText = nextElement as MediaText;
          nextText.extra.text = element.extra.text;
          nextText.sample = element.sample;
          updateTextPropertiesWithoutRender(nextText, nextText, undefined);
        }
      }
    });
  });
  return newDesign;
}

export default applyDesign;
