import cn from 'classnames';
import React from 'react';

import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

interface Props {
  disabled?: boolean;
  on?: boolean;
  noBorder?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  stopPropagation?: boolean;
  className?: string;
  children: React.ReactNode;
  theme?: ControlButtonTheme;
  ignoreOutsideClick?: boolean;
}

export enum ControlButtonTheme {
  NORMAL = 'NORMAL',
  SEMITRANSPARENT = 'SEMITRANSPARENT',
}

function ControlButton({
  disabled,
  on,
  noBorder,
  onClick,
  stopPropagation,
  className,
  children,
  theme,
  ignoreOutsideClick,
}: Props) {
  const isMobile = useIsMobile();

  const handleClick = (e: React.MouseEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div
      className={cn(className, 'button', styles.controlButton, {
        [styles.mobile]: isMobile,
        [styles.on]: on,
        'cy-button-on-state': on,
        [styles.disabled]: disabled,
        disabled,
        [styles.semitransparent]: theme === ControlButtonTheme.SEMITRANSPARENT,
        [styles.noBorder]: noBorder,
      })}
      onClick={handleClick}
      data-ignore-outside-click={ignoreOutsideClick}
    >
      {children}
    </div>
  );
}

export default ControlButton;
